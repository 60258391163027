<template>
  <Row class="page5">
    <Col style="width:48%;padding:0 1%;">
      <div class="left">
        <china-map ref="chinaMap"></china-map>
      </div>
    </Col>
    <Col style="width:51.5%;height: 100%;display: flex;flex-direction: column">
      <div class="center-bottom">
        <span class='title'><span class="title-text">项目简介</span></span>
        <span class="angle1"></span>
        <span class="angle2"></span>
        <span class="angle3"></span>
        <span class="angle4"></span>
        <span class="describe">在宁乡金洲村，佛光强县志质者为一名受灾“五保户”捡拾时，刚到门口等接到一段浓浓的刺皇异味，但老人家还住在里面。去愿者们奋战10多人，把房间清归得干净舒活。我们在同行的草位闻事的下小时讲课下，激动了地写:“现场，所有人，回想在日本听CODE日本志愿者看懂在救灾中的日常行动，当时我觉得日本救灾工作宣传，跟日本的大隔阂，现在我要说这话了，中国的灾祸的中国的志愿者部署甚微!</span>
      </div>
      <div class="bottom-list">
        <span class='title'><span class="title-text">项目图片</span></span>
        <span class="angle1"></span>
        <span class="angle2"></span>
        <span class="angle3"></span>
        <span class="angle4"></span>
        <pictures></pictures>
      </div>
    </Col>
  </Row>
</template>

<script>
const chinaMap  = () => import('./components/chinaMap');
const pictures = ()=> import('./components/workPicture/picture');

export default {
  name: 'workPicture',
  components: {
    chinaMap,
    pictures,
  },
  data() {
    return{

    }
  },
  mounted() {
    this.resizeFn = this.$debounce(()=> {
      // 通过捕获系统的onresize事件触发我们需要执行的事件
      // this.$refs.channelBar1.setChart();
      // for (let i = 1; i < 9; i++) {
      //   this.$refs['chart' + i].setChart()
      //
      // }
      this.$refs.chinaMap.setMap();
      // this.$refs.hnPronvice.mapEchartsInit();
      // this.$refs.hotWords.setChart();

    }, 500)
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFn)
  }
}
</script>

<style lang="less" scoped>
.page5 {
  margin-top: 20px;
  height: 98%;
  width: 100%;
  padding: 14px 20px 20px;
  background: #03044A;
  overflow: hidden;
  .ivu-col {
    height: 100%;
    float: left;
  }

  .left, .right1-1,.center-bottom{
    height: 100%;
    border: 1px solid #0D2451;
    position: relative;
    background: #151456;


    #left_5 {
      height: 100%;
      width: 45%;
      float: left;
    }

    #left_6 {
      height: 100%;
      width: 55%;
      float: left;
    }

    .circular {
      height: 100%;

      .canvas {
        height: 100%;
        width: 30%;
        float: left;

        .subtitle {
          font-size: 12px;
          font-weight: bold;
          color: #fff;
          height: 25px;
          padding: 10px 0 ;
          text-align: center;
        }

        .canvasList {
          height: calc(~'100% - 25px');
          text-align: center
        }
      }
    }

    .left1 {
      border-bottom: 1px solid #0D2451;
      background: #151456;
    }

    .title {
      position: absolute;
      display: inline-block;
      color: #6EDDF1;
      border: 2px solid #6EDDF1;
      height: 18px;
      padding: 0 2px;
      left: 50%;
      transform: translate(-50%, -50%);

      .title-text {
        position: relative;
        font-size: 16px;
        background: #09102E;
        display: inline-block;
        padding: 0 4px;
        transform: translateY(-5px);
      }
    }
  }

  .bottom-list{
    border: 1px solid #0D2451;
    position: relative;
    background: #151456;
    box-sizing: border-box;

    .title {
      position: absolute;
      display: inline-block;
      color: #6EDDF1;
      border: 2px solid #6EDDF1;
      height: 18px;
      padding: 0 2px;
      left: 50%;
      transform: translate(-50%, -50%);

      .title-text {
        position: relative;
        font-size: 16px;
        background: #09102E;
        display: inline-block;
        padding: 0 4px;
        transform: translateY(-5px);
      }
    }
  }
  .bottom-list{
    height: 80%;
  }

  .center-bottom {
    height: 18%!important;
    margin-bottom: 2%;
    padding: 0 10px;
    border: 1px solid #0D2451;
    position: relative;
    background: #151456;
    .describe{
      display: inline-block;
      margin-top: 20px;
      //width: 30%;
      height: 98%;
      font-size: 17px;
      color: #3d83e7;
    }
  }

  .right-1 {
    height: 30%;

    .right1-1 {
      height: 92%;
    }
  }

  .right-2 {
    height: 40%;

  }
}
</style>
